import {
  Box,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Flex,
  Spacer,
  Link,
  Stack,
  Image,
  Tag,
  TagLabel,
  Divider,
} from "@chakra-ui/react";
import parse from "html-react-parser";
import { timeDiff, dateFormatter } from "@/common/utility/dateTimeUtil";
import { StyledEm } from "../feed-layout-styled";
import PostToolbar from "./post-toolbar";
import DOMPurify from "dompurify";

import {
  IPostModalProps,
  IPostToolbarProps,
} from "@/interfaces/IPostModalProps";
import { useEffect, useState, useRef } from "react";
import { PostModalProvider } from "./post-modal-context";
import { useLivestreamsApi } from "@/components/feeds/modal/post-modal-context";
import { useUserInfo } from "@/common/context/user-info-context";
import { CustomToast } from "@/common/toast/CustomToast";
import MapComponent from "./map-component";
import { ScrollableWrapper } from "@/common/div/wrappers";
import { toTitleCase } from "@/common/utility/toTitleCase";
import { findAll } from "highlight-words-core";
import { useLanguage } from "@/components/userprofile/language/lang-context";
const fetchSpotlightPost = async (uniqueId: string, searchId: string) => {
  try {
    const response = await fetch(
      `/api/livestreams/getSpotlightPost?uniqueId=${uniqueId}&searchId=${searchId}`
    );
    if (response.ok) {
      const post = await response.json();
      return post;
    }
  } catch (error) { }
};
export const PostModal = ({
  showPostModal,
  post,
  theme,
  ...otherProps
}: IPostModalProps) => {
  //if (post.machineLearningScores && post.machineLearningScores.length > 1)
  //console.log('rendered post-modal with post ===>', post)
  const [localPost, setLocalPost] = useState(post);
  const [spotlightPost, setSpotlightPost] = useState<any>({});
  const { userInfo } = useUserInfo();
  const user = userInfo();
  const { onTranslatePost } = useLivestreamsApi();
  const {
    isOpen: isOpenToolbar,
    onOpen: onOpenToolbar,
    onClose: onCloseToolbar,
  } = useDisclosure();

  const { isOpen, onClose, onOpen } = useDisclosure();
  const {
    state: { lang },
  } = useLanguage();
  const postToolbarProps: IPostToolbarProps = {
    post: localPost,
    theme,
    user,
    lang,
    ...otherProps,
  };

  const { errorToast } = CustomToast();
  const isFirstRender = useRef(true);
  const [postContent, setPostContent] = useState("");

  interface EntityType {
    type: string;
    value: string;
  }

  // Define the color schemes for each type
  const typeColors = {
    LOCATION: "blue",
    PERSON: "green",
    ORGANIZATION: "purple",
  };

  // Add a state to store the unique entities
  const [uniqueEntities, setUniqueEntities] = useState([]);
  const [locations, setLocations] = useState([]);
  const [enableEnrichedText, setEnableEnrichedText] = useState(false);
  const [searchWords, setSearchWords] = useState([]);
  const [enrichedHighlightColor, setEnrichedHighlightColor] = useState("");

  useEffect(() => {

    (async () => {
      const spotlightResult = await fetchSpotlightPost(localPost?.uniqueId, localPost.searchUid);
      if (spotlightResult) {
        setSpotlightPost(spotlightResult);
        // console.log(spotlightResult);
      }
    })();

  }, []);
  // When the component mounts or when the post?.namedEntities changes, update the uniqueEntities state.
  useEffect(() => {
    // Filter out duplicates based on some criteria, for example, 'value'
    const uniqueEntitiesSet = new Set();
    const filteredEntities = localPost?.namedEntities?.entities?.filter(
      (entity) => {
        if (
          !uniqueEntitiesSet.has(entity.value) &&
          entity.type !== "LOCATION"
        ) {
          uniqueEntitiesSet.add(entity.value);
          return true;
        }
        return false;
      }
    );

    setUniqueEntities(filteredEntities);

    const uniqueLocationsSet = new Set();
    let filteredLocations = localPost?.namedEntities?.locations?.filter(
      (location) => {
        if (!uniqueLocationsSet.has(location.geoNameId)) {
          uniqueLocationsSet.add(location.geoNameId);
          return true;
        }
        return false;
      }
    );

    if (localPost?.hasGeoLocation) {
      const geoLocation = {
        coordinates: {
          lat: localPost.geoLatitude,
          lon: localPost.geoLongitude,
        },
        name: "Original Geo Location",
        geoNameId: "-",
      };

      if (!filteredLocations) {
        filteredLocations = [];
      }

      filteredLocations.push(geoLocation);
    }

    if (localPost?.hasAssumedGeoLocation) {
      const assumedGeoLocation = {
        coordinates: {
          lat: localPost.assumedGeoLatitude,
          lon: localPost.assumedGeoLongitude,
        },
        name: "Possible User Location",
        geoNameId: "-",
      };

      if (!filteredLocations) {
        filteredLocations = [];
      }

      filteredLocations.push(assumedGeoLocation);
    }

    setLocations(filteredLocations);
  }, [localPost?.namedEntities]);

  // Group the entities by their entityType
  const entitiesByType: { [key: string]: EntityType[] } =
    uniqueEntities?.reduce((acc, entity) => {
      if (!acc[entity.type]) {
        acc[entity.type] = [];
      }
      acc[entity.type].push(entity);
      return acc;
    }, {});

  if (isFirstRender.current && post) {
    onOpenToolbar();
    setPostContent(localPost.content);
    isFirstRender.current = false;
  }

  const ParsedText = ({ content }) => {
    if (!content) return <></>;

    const replaceEm = (node) => {
      if (
        node.type === "tag" &&
        node.name === "em" &&
        node.children &&
        node.children[0]?.type === "text"
      ) {
        return <StyledEm theme={theme}>{node.children[0].data}</StyledEm>;
      } else if (
        node.type === "tag" &&
        node.name === "em" &&
        node.children &&
        node.children[0]?.type === "tag" &&
        node.children[0]?.name === "em"
      ) {
        return (
          <StyledEm theme={theme}>{node.children[0].children[0].data}</StyledEm>
        );
      }
    };
    content = DOMPurify.sanitize(content);
    return <>{parse(content, { replace: replaceEm })}</>;
  };

  const placeholderAuthorImage = "/images/noavatar.jpg";
  const [authorImageError, setAuthorImageError] = useState(false);
  const handleAuthorImageError = () => {
    setAuthorImageError(true);
  };

  const placeholderPostImage = "/images/no_image.jpg";
  const [postImageError, setPostImageError] = useState(false);
  const handlePostImageError = () => {
    setPostImageError(true);
  };

  const translatePost = async () => {
    if (localPost) {
      if (localPost.formattedText.length >= 10000) {
        errorToast("Post", "Maximum translated post is 10000 character.");
        return;
      }

      const postTranslation = {
        languageCode: user?.translationLanguage
          ? user?.translationLanguage
          : "en",
        text: localPost.content,
        sourceType: localPost.sourceType,
        isShowMore: false,
        uniqueId: localPost.uniqueId,
        unmarkTranslation: localPost.isTranslated,
        isAlert: false
        };

        localPost.isTranslated = !localPost.isTranslated;

      const res = await onTranslatePost(postTranslation);
      if (res.response.ok) {
        const translatedText = res.data?.text;
        setPostContent(translatedText);
      }
    }
  };

  const handleOnClose = () => {
    setPostContent(localPost?.content); //reset the data to original state
    isFirstRender.current = true;
    onClose();
    otherProps.onCloseModal();
  };

  return (
    <Modal
      isOpen={showPostModal}
      onClose={handleOnClose}
      scrollBehavior="inside"
      isCentered
      colorScheme={"dark"}
    >
      <ModalOverlay />
      <ScrollableWrapper>
        <ModalContent color={"dark"} maxW="65rem" padding={2}>
          <ModalCloseButton />
          <ModalBody>
            <Flex direction="row">
              <Stack direction="column" width="80%" ml={-4}>
                <Flex direction="row">
                  <Stack
                    direction="column"
                    minWidth="125px"
                    spacing={0}
                    fontSize="sm"
                  >
                    {localPost?.authorImageUrl ? (
                      <Image
                        alt=""
                        src={
                          authorImageError
                            ? placeholderAuthorImage
                            : localPost?.authorImageUrl
                        }
                        onError={handleAuthorImageError}
                        width={100}
                        height={100}
                        style={{
                          objectFit: "fill",
                        }}
                      />
                    ) : (
                      <Image
                        alt=""
                        src={placeholderAuthorImage}
                        width={100}
                        height={100}
                        style={{
                          objectFit: "fill",
                        }}
                      />
                    )}
                    <Text fontWeight="bold" fontSize="0.95em" pt={1}>
                      <ParsedText
                        content={
                          localPost?.authorName || localPost?.authorId || ""
                        }
                      />
                    </Text>
                    <Text fontSize="0.75em">
                      {lang?.ADVANCED_POST_DATE_RECEIVED || "Date received"} <br />
                      {dateFormatter({
                        value: localPost?.insertedDate,
                        formatType: "dd MMM yyyy hh:mm:ss a",
                      })}
                    </Text>
                    <Text fontSize="0.75em">
                      {`(${timeDiff(localPost?.insertedDate)})`} <br />
                      <br />
                    </Text>
                    <Text fontSize="0.75em">
                      {lang?.ADVANCED_POST_DATE_PUBLISHED || "Date published"} <br />
                      {dateFormatter({
                        value: localPost?.publishDate,
                        formatType: "dd MMM yyyy hh:mm:ss a",
                      })}
                    </Text>
                    <Text fontSize="0.75em">
                      {`(${timeDiff(localPost?.publishDate)})`}
                    </Text>
                  </Stack>
                  <Stack direction="column" width="100%">
                    {localPost?.isDuplicate && (
                      <Stack direction="row" color="orange.500">
                        <i className="fas fa-star"></i>
                        <Text fontWeight="bold" fontSize="0.8rem" ml={1}>
                          {"[This post is a duplicate within your organization]"}
                        </Text>
                      </Stack>
                    )}
                    {localPost?.title && (
                      <Text fontWeight="bold" fontSize="1.2rem" mb={2}>
                        <ParsedText content={localPost.title} />
                      </Text>
                    )}
                    {!enableEnrichedText && (
                      <Text fontSize="0.88em" wordBreak="break-word">
                        <ParsedText content={postContent?.replace("\r\n", "<br/><br/>")} />
                      </Text>
                    )}
                    {enableEnrichedText && (
                      <Text fontSize="0.88em" wordBreak="break-word">
                        <ParsedText
                          content={findAll({
                            caseSensitive: false,
                            searchWords: searchWords,
                            textToHighlight: postContent,
                          })
                            .map((chunk) => {
                              const { end, highlight, start } = chunk;
                              const text = postContent.substr(
                                start,
                                end - start
                              );
                              if (highlight) {
                                return `<label style='background-color:${enrichedHighlightColor}'>${text}</label>`;
                              } else {
                                return text;
                              }
                            })
                            .join("")}
                        />
                      </Text>
                    )}
                    {localPost?.rcInfo && (
                      <Flex
                        flexDirection="column"
                        fontSize="0.88em"
                        wordBreak="break-word"
                      >
                        <Flex flexDirection="row">
                          <Flex mt={5} flexDirection="column">
                            <Box mb={2}>
                              <Text fontWeight={"bold"}>{lang?.CATEGORY || "Category"}</Text>
                              <Text>
                                {localPost?.rcInfo?.category
                                  ? localPost.rcInfo?.category
                                  : lang?.NO_INFORMATION || "No Information"}
                              </Text>
                            </Box>
                            <Spacer />
                            <Box>
                              <Text fontWeight={"bold"}>{lang?.TYPE || "Type"}</Text>
                              <Text>
                                {localPost.rcInfo?.type
                                  ? localPost.rcInfo?.type
                                  : lang?.NO_INFORMATION || "No Information"}
                              </Text>
                            </Box>
                          </Flex>
                          <Flex mt={5} ml={10} flexDirection="column">
                            <Box mb={2}>
                              <Text fontWeight={"bold"}>{lang?.SEVERITY || "Severity"}</Text>
                              <Text>
                                {localPost?.rcInfo?.severity
                                  ? localPost.rcInfo?.severity
                                  : lang?.NO_INFORMATION || "No Information"}
                              </Text>
                            </Box>
                            <Spacer />
                            <Box>
                              <Text fontWeight={"bold"}>{lang?.CITY || "City"}</Text>
                              <Text>
                                {localPost?.rcInfo?.city
                                  ? localPost.rcInfo?.city
                                  : lang?.NO_INFORMATION || "No Information"}
                              </Text>
                            </Box>
                          </Flex>
                          <Flex mt={5} ml={10} flexDirection="column">
                            <Box mb={2}>
                              <Text fontWeight={"bold"}>{lang?.STATEPROVINCE || "State/Province"}</Text>
                              <Text>
                                {localPost?.rcInfo?.stateProvince
                                  ? localPost.rcInfo?.stateProvince
                                  : lang?.NO_INFORMATION || "No Information"}
                              </Text>
                            </Box>
                            <Spacer />
                            <Box>
                              <Text fontWeight={"bold"}>{lang?.COUNTRY || "Country"}</Text>
                              <Text>
                                {localPost?.rcInfo?.country
                                  ? localPost.rcInfo?.country
                                  : lang?.NO_INFORMATION || "No Information"}
                              </Text>
                            </Box>
                          </Flex>
                        </Flex>
                        <Flex mt={5}>
                          <Box mb={2}>
                            <Text fontWeight={"bold"}>{lang?.DESCRIPTION || "Description"}</Text>
                            <Text wordBreak="break-word">
                              {localPost?.rcInfo?.description
                                ? localPost.rcInfo?.description
                                : lang?.NO_INFORMATION || "No Information"}
                            </Text>
                          </Box>
                        </Flex>
                        <Flex
                          mt={5}
                          justifyContent={"flex-start"}
                          flexDirection="column"
                        >
                          <Box mb={2}>
                            <Text fontWeight={"bold"}>{lang?.ATTACHMENTS || "Attachments"}</Text>
                            {localPost?.rcInfo?.attachments.length > 0
                              ? localPost?.rcInfo?.attachments?.map(
                                  (attachment, index) => (
                                    <Text key={index}>
                                      <Link
                                        href={attachment}
                                        isExternal
                                        color="blue.200"
                                        cursor={"pointer"}
                                      >
                                        {" "}
                                        {attachment}
                                      </Link>
                                    </Text>
                                  )
                                )
                              : lang?.NO_INFORMATION || "No Information"}
                          </Box>
                          <Box mb={2}>
                            <Text fontWeight={"bold"}>Id</Text>
                            <Text>
                              {localPost.rcInfo?.incidentId
                                ? localPost.rcInfo?.incidentId
                                : lang?.NO_INFORMATION || "No Information"}
                            </Text>
                          </Box>
                        </Flex>
                      </Flex>
                    )}
                  </Stack>
                </Flex>
                <Flex direction="row" justifyContent="space-between">
                  <Stack
                    direction="column"
                    spacing={1}
                    mt={4}
                    justifyContent="start"
                  >
                    {locations && locations.length > 0 && (
                      <>
                        <Text fontSize="sm" fontWeight={"bold"}>
                          {lang?.ADVANCED_POST_MENTIONEDLOCATIONS || "Mentioned Locations"}
                        </Text>
                        <MapComponent
                          width={"380px"}
                          height={"200px"}
                          mapCenter={{ lat: 25, lng: 25 }}
                          zoom={locations.length === 1 ? 3 : 1}
                          markers={locations?.map((x) => {
                            return {
                              lat: Number(x.coordinates?.lat),
                              lng: Number(x.coordinates?.lon),
                              locationName: x.name,
                            };
                          })}
                          postMarkers={[]}
                        />
                      </>
                    )}
                  </Stack>
                  <Stack
                    direction="column"
                    spacing={1}
                    mt={4}
                    justifyContent="end"
                  >
                    {localPost?.imageUrl && (
                      <>
                        <Text fontSize="sm" fontWeight={"bold"}>
                          {lang?.ADVANCED_POST_ATTACHED_IMAGE || "Attached Image"}
                        </Text>
                        <Image
                          fill=""
                          alt=""
                          src={
                            postImageError
                              ? placeholderPostImage
                              : localPost?.imageUrl
                          }
                          onError={handlePostImageError}
                          width={380}
                          height={200}
                          style={{
                            maxWidth: "380px",
                            maxHeight: "200px",
                            objectFit: "fill",
                          }}
                        />
                      </>
                    )}
                  </Stack>
                </Flex>
              </Stack>
              <Stack direction="row" width="20%">
                <Divider ml={3} orientation="vertical" />
                <ScrollableWrapper>
                  <Stack
                    overflowX="hidden"
                    style={{ direction: "rtl" }}
                    overflowY="auto"
                    maxHeight="100vh"
                  >
                    {isOpenToolbar && (
                      <PostModalProvider>
                        <Stack ml={1} spacing={-1} style={{ direction: "ltr" }}>
                          <PostToolbar
                            {...postToolbarProps}
                            translateTo={translatePost}
                            onClose={onClose}
                          />
                        </Stack>
                      </PostModalProvider>
                    )}
                    {uniqueEntities && entitiesByType && (
                      <Stack
                        spacing={1}
                        style={{ direction: "ltr" }}
                        direction="column"
                      >
                        {Object.entries(entitiesByType).map(
                          ([type, entities]) => (
                            <Stack
                              spacing={1}
                              mt={1}
                              direction="column"
                              key={type}
                            >
                              <Text
                                fontWeight="bold"
                                fontSize="sm"
                                ml={1}
                                onClick={() => {
                                  setEnableEnrichedText(!enableEnrichedText);
                                  setEnrichedHighlightColor(typeColors[type]);
                                  setSearchWords(
                                    entities.map((x) => {
                                      if (x.type === type) return x.value;
                                    })
                                  );
                                }}
                              >
                                {`${toTitleCase(type)}s`}
                              </Text>
                              <Flex direction="row" flexWrap="wrap">
                                {entities.map((entity, idx) => (
                                  <Flex key={idx}>
                                    <Tag
                                      mb={1}
                                      ml={1}
                                      minWidth="65px"
                                      title={`${toTitleCase(type)}:${
                                        entity.value
                                      }`}
                                      size="sm"
                                      variant="solid"
                                      key={`${localPost?.namedEntities?.uniqueId}-${type}-${idx}`} // Include 'type' in the key to avoid potential key conflicts
                                      colorScheme={typeColors[type] || "blue"} // Use the color scheme based on the type
                                    >
                                      <TagLabel fontSize="0.85em">
                                        {toTitleCase(entity.value)}
                                      </TagLabel>
                                    </Tag>
                                  </Flex>
                                ))}
                              </Flex>
                            </Stack>
                          )
                        )}
                      </Stack>
                    )}
                    {locations && locations.length > 0 && (
                      <Stack
                        spacing={1}
                        style={{ direction: "ltr" }}
                        direction="column"
                      >
                        <Stack spacing={0.5} mt={0} direction="column">
                          <Text fontWeight="bold" fontSize="sm" ml={1}>
                            {lang?.ADVANCED_POST_LOCATIONS || "Locations"}
                          </Text>
                          <Flex direction="row" flexWrap="wrap">
                            {locations.map((location, idx) => (
                              <Flex key={idx}>
                                <Tag
                                  mb={1}
                                  ml={1}
                                  minWidth="65px"
                                  title={`${location?.name} [lat:${location?.coordinates?.lat} lng: ${location?.coordinates?.lon}]`}
                                  size="sm"
                                  variant="solid"
                                  key={`${localPost?.namedEntities?.uniqueId}-${location?.geoNameId}-${idx}`} // Include 'type' in the key to avoid potential key conflicts
                                  colorScheme={"blue"} // Use the color scheme based on the type
                                >
                                  <TagLabel fontSize="0.85em">
                                    {toTitleCase(location.name)}
                                  </TagLabel>
                                </Tag>
                              </Flex>
                            ))}
                          </Flex>
                        </Stack>
                      </Stack>
                    )}
                    {spotlightPost && spotlightPost.sentiment && (
                      <Stack
                        spacing={1}
                        style={{ direction: "ltr" }}
                        direction="column"
                      >
                        <Stack spacing={0.5} mt={0} direction="column">
                          <Text fontWeight="bold" fontSize="sm" ml={1}>
                            {lang?.MENU_SPOTLIGHT_SENTIMENT_TITLE || "Sentiments"}
                          </Text>
                          <Flex direction="row" flexWrap="wrap">
                            <Flex>
                              <Tag
                                mb={1}
                                ml={1}
                                minWidth="65px"
                                title={`${spotlightPost.sentiment}`}
                                size="sm"
                                variant="solid"
                                colorScheme={"teal"} // Use the color scheme based on the type
                              >
                                <TagLabel fontSize="0.85em">
                                  {toTitleCase(spotlightPost.sentiment)}
                                </TagLabel>
                              </Tag>
                            </Flex>
                          </Flex>
                        </Stack>
                      </Stack>
                    )}
                    {spotlightPost && spotlightPost.emotion && (
                      <Stack
                        spacing={1}
                        style={{ direction: "ltr" }}
                        direction="column"
                      >
                        <Stack spacing={0.5} mt={0} direction="column">
                          <Text fontWeight="bold" fontSize="sm" ml={1}>
                            {lang?.MENU_SPOTLIGHT_EMOTIONS_TITLE || "Emotions"}
                          </Text>
                          <Flex direction="row" flexWrap="wrap">

                            {spotlightPost.emotion.map((emotion, idx) => (
                              <Flex key={idx}>
                                <Tag
                                  mb={1}
                                  ml={1}
                                  minWidth="65px"
                                  title={`${emotion}`}
                                  size="sm"
                                  variant="solid"
                                  colorScheme={"pink"} // Use the color scheme based on the type
                                >
                                  <TagLabel fontSize="0.85em">
                                    {toTitleCase(emotion)}
                                  </TagLabel>
                                </Tag>
                              </Flex>

                            ))}
                          </Flex>
                        </Stack>
                      </Stack>
                    )}
                  </Stack>
                </ScrollableWrapper>
              </Stack>
            </Flex>
          </ModalBody>
        </ModalContent>
      </ScrollableWrapper>
    </Modal>
  );
};
