import React, { useEffect, useState } from 'react';
import { GoogleMap, MarkerF, InfoWindowF, InfoWindow } from '@react-google-maps/api';
import { IGeo, IMapData } from '@/interfaces/IGeo';
import { Box, useTheme } from '@chakra-ui/react';
import PostViewById from '../post-viewBy-id';


const MapComponent = (props: IMapData) => {
    const [mapRef, setMapRef] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [infoWindowData, setInfoWindowData] = useState(null);
    const [selectedPost, setSelectedPost] = useState(null);
    const [showPostModal, setShowPostModal] = useState(false);

    const onMapLoad = (map) => {
        setMapRef(map);
        const bounds = new google.maps.LatLngBounds();
        props.markers?.forEach(({ lat, lng }) => bounds.extend({ lat, lng }));
        if (props.markers.length > 1)
            map.fitBounds(bounds);
        else {
            map.setCenter({ lat: props.markers[0].lat, lng: props.markers[0].lng });
            map.setZoom(props.zoom);
        }
    };
    const onSearchViewMapLoad = (map) => {
        setMapRef(map);
        const bounds = new google.maps.LatLngBounds();
        props.postMarkers?.forEach(({ lat, lng }) => bounds.extend({ lat, lng }));
        map.fitBounds(bounds);
    };

    // const updateMapState = (name: keyof IGeo, value: any) => {

    //     setMap((prevState) => ({ ...prevState, [name]: value }));
    // }


    // const onMarkerRightClick = (e: google.maps.MapMouseEvent): void => {
    //     updateMapState("defaultMarker", null);
    // }

    const handleMarkerClick = (id, lat, lng, locationName) => {
        setInfoWindowData({ id, locationName });
        setIsOpen(true);
    };
    const handlePostMarkerClick = (post, lat, lng,) => {
        mapRef?.panTo({ lat, lng });
        //setInfoWindowData({ id, address });

        setShowPostModal(true);
        setSelectedPost(post);
    };
    function getMapOptions() {
        return {
            streetViewControl: false,
            scaleControl: false,
            fullscreenControl: true,
            styles: [{
                featureType: "poi.business",
                elementType: "labels",
                stylers: [{
                    visibility: "off",
                }],
            },
            {
                featureType: "transit",
                elementType: "labels.icon",
                stylers: [{ visibility: "off" }],
            },
            ],
            gestureHandling: "greedy",
            disableDoubleClickZoom: true,
            mapTypeControl: false,
            mapTypeId: google.maps.MapTypeId.HYBRID,
            mapTypeControlOptions: {
                style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
                position: google.maps.ControlPosition.BOTTOM_CENTER
            },
            zoomControl: false,
            clickableIcons: false,
            keyboardShortcuts: false,
            controlSize: 20
        };
    }

    return (
        <Box>

            <GoogleMap
                id="post-modal-map"
                mapContainerStyle={{ width: props.width, height: props.height }}
                options={getMapOptions()}
                clickableIcons={true}
                onLoad={props?.markers.length > 0 ? onMapLoad : onSearchViewMapLoad}               

            >
                {props.markers?.map(({ lat, lng, locationName }, ind) => (
                    <MarkerF
                        icon={"https://maps.google.com/mapfiles/ms/icons/blue-dot.png"}
                        key={ind}
                        position={{ lat, lng }}
                        onMouseOver={() => {
                            handleMarkerClick(ind, lat, lng, locationName);
                        }}
                        onMouseOut={() => {
                            setIsOpen(false);
                        }}
                    >
                        {isOpen && infoWindowData?.id === ind && infoWindowData.locationName && (
                            <InfoWindowF
                                options={{ disableAutoPan: true }}
                                onCloseClick={() => {
                                    setIsOpen(false);
                                }}>
                                <p style={{ fontSize: 12, fontWeight: 'bolder', color: 'black' }}>{infoWindowData.locationName}</p>
                            </InfoWindowF>
                        )}

                    </MarkerF>
                ))}
                {props?.postMarkers?.map(({ lat, lng, post, isLoc, isEntityLoc }, ind) => (
                    <MarkerF
                        icon={isLoc ? "https://maps.google.com/mapfiles/ms/icons/blue-dot.png" : "https://maps.google.com/mapfiles/ms/icons/orange-dot.png"}
                        key={ind}
                        position={{ lat, lng }}                       
                        onClick={() => {
                            handlePostMarkerClick(post, lat, lng);
                        }}

                    >
                        {/* {isOpen && infoWindowData?.id === ind && (
                            <InfoWindow
                                onCloseClick={() => {
                                    setIsOpen(false);
                                }}
                            >
                                <h3>{infoWindowData.address}</h3>
                            </InfoWindow>
                        )} */}

                    </MarkerF>
                ))}
                {showPostModal && selectedPost && (
                    <>
                        <PostViewById postId={selectedPost} />
                    </>
                )}
            </GoogleMap>
        </Box>
    )
}

export default MapComponent;