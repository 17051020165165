import React, { useState, useEffect } from 'react';
import Select, { components } from 'react-select';
import { ITopic } from "@/interfaces/ITopic";
import { useColorModeValue, useTheme, Spinner } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { ScrollableWrapper } from '@/common/div/wrappers';

interface TopicSelectProps {
    tabIndex: number
    placeholder: string;
    selectedTopic: ITopic;
    onTopicChange: (selectedTopic: ITopic) => void;
}

const TopicSelect = (props: TopicSelectProps) => {
    // Destructure the placeholder, onTopicChange, and selectedTopic props
    const { placeholder, onTopicChange, selectedTopic } = props;
    const [isLoading, setIsLoading] = useState(true);
    const [topics, setTopics] = useState<ITopic[]>([]);
    const [selectedOption, setSelectedOption] = useState<ITopic>(selectedTopic);
    const theme = useTheme();
    const menuBackgroundColor = useColorModeValue(theme.colors.white, theme.colors.gray[700]);
    const optionColor = useColorModeValue(theme.colors.black, theme.colors.white);
    const reverseOptionColor = useColorModeValue(theme.colors.white, theme.colors.black);
    const onHoverOptionBackgoundColor = useColorModeValue(theme.colors.blue[500], theme.colors.blue[200]);
    const inputActiveBorderColor = useColorModeValue(theme.colors.blue[600], theme.colors.blue[300]);
    const selectedOptionBackgoundColor = useColorModeValue(theme.colors.blue[200], theme.colors.blue[400]);
    const bg = useColorModeValue(theme.colors.gray[100], theme.colors.whiteAlpha[50]);
    const activeBg = useColorModeValue(theme.colors.white[100], theme.colors.gray[700]);
    const hoverBg = useColorModeValue(theme.colors.gray[200], theme.colors.whiteAlpha[100]);

    const LoadingIndicator = props => {
        return (
            <Spinner size="sm" color={optionColor} />
        );
    };

    const onChange = (item) => {
        let selectedItem = topics.find(x => x.id == item.value);
        setSelectedOption(selectedItem);

        // Call the passed onTopicChange function with the updated event
        if (typeof onTopicChange === 'function') {
            onTopicChange(selectedItem);
            setSelectedOption(selectedItem)
        }
    };

    useEffect(() => {
        // Retrieve topics from API
        async function fetchTopics() {
            try {
                const response = await fetch(`/api/streams/topics/getAll?take=${9999}`);
                const data = await response.json() as ITopic[];
                setTopics(data);
                setIsLoading(false);
            } catch (e) {
                console.error('Invalid API response:', e);
                setIsLoading(false);
            }
        }
        fetchTopics();
    }, []);

    useEffect(() => {
        setSelectedOption(selectedTopic);
    }, [selectedTopic]);


    const options = topics ? topics?.map((topic) => ({
        value: topic.id,
        label: topic.name,
    })) : [];

    const DropdownIndicator = props => {
        return (
            <components.DropdownIndicator {...props}>
                <ChevronDownIcon fontSize="22px"></ChevronDownIcon>
            </components.DropdownIndicator>
        );
    };

    return (
        <ScrollableWrapper>
            <Select
                tabIndex={props.tabIndex}    
                isDisabled={isLoading}
                isLoading={isLoading}
                placeholder={placeholder}
                options={options}
                onChange={onChange}
                value={selectedOption ? { value: selectedOption.id, label: selectedOption.name } : null}
                isSearchable
                components={{ IndicatorSeparator: () => null, DropdownIndicator: DropdownIndicator, LoadingIndicator: LoadingIndicator, }}
                styles={{
                    input: base => ({
                        ...base,
                        color: optionColor,
                    }),
                    placeholder: base => ({
                        ...base,
                        paddingLeft: '5px'
                    }),
                    singleValue: base => ({
                        ...base,
                        color: optionColor,
                        paddingLeft: '5px'
                    }),
                    control: (provided, state) => ({
                        ...provided,
                        background: state.menuIsOpen ? activeBg : bg,
                        boxShadow: 'initial',
                        borderWidth: state.menuIsOpen ? 2 : 0,
                        borderColor: inputActiveBorderColor,
                        borderRadius: '5px',
                        '&:hover': {
                            background: hoverBg,
                        },
                    }),
                    menu: (provided, state) => ({
                        ...provided,
                        backgroundColor: menuBackgroundColor,
                        borderWidth: 1,
                    }),
                    option: (provided, state) => ({
                        ...provided,
                        zIndex: 2002,
                        backgroundColor: state.isSelected ? selectedOptionBackgoundColor : menuBackgroundColor,
                        color: state.isSelected ? reverseOptionColor : optionColor,
                        '&:hover': {
                            backgroundColor: onHoverOptionBackgoundColor,
                            color: reverseOptionColor
                        },
                    }),
                    dropdownIndicator: (provided) => ({
                        ...provided,
                        marginRight: '2px',
                        color: optionColor,
                    }),


                }}
            />
        </ScrollableWrapper>
    );
};

export default TopicSelect;
